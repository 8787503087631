import React from "react";
import {
  SEO,
  PageLayout,
  FadeReveal,
  MarkdownContent,
  Image,
  BasicCarousel,
  multipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Grid
            className={"about-content-container"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
              className={"markdown-column"}
            >
              <Header
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "content_section_header",
                  defaultValue: "About",
                })}
              />
              <FadeReveal duration={1250} triggerOnce={true}>
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "content_section_description",
                  })}
                />
              </FadeReveal>
            </Grid.Column>
            <Grid.Column width={8} className={"image-column"}>
              <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Content.Image",
                    componentIdentifier: "content_section_image",
                    numToSelect: 1,
                  })}
                  background={false}
                  className={"image"}
                />
              </FadeReveal>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            className={"about-staff-container"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Header
                as={"h2"}
                className={"about-staff-header"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_header",
                  defaultValue: "OUR TOP STYLISTS",
                })}
              />
              <MarkdownContent
                className={"about-staff-description"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "staff_section_description",
                })}
              />
              <BasicCarousel
                className={"featured-stylists"}
                size={3}
                displayIndicators={false}
                displayPlayPauseButton={true}
                autoScroll={true}
                slide={true}
                items={multipleComponentIterator({
                  iterator: allFishermanBusinessTeam.nodes,
                  components: [
                    {
                      component: (
                        <SlideReveal
                          className={"ui card"}
                          fade={true}
                          direction={"up"}
                          triggerOnce={true}
                        />
                      ),
                      propMap: { key: "_id" },
                      children: [
                        {
                          component: (
                            <BackgroundImageTeamMember
                              useModal={false}
                              as={InternalLink}
                            />
                          ),
                          propMap: {
                            "teamMember.name": "name",
                            "teamMember.role": "role",
                            "teamMember.photo": "gatsbyImage",
                            "teamMember.description": "description",
                            "teamMember.email": "email",
                            "teamMember.phone": "phone",
                            "teamMember.locations": "locations",
                            "teamMember.hours": "hours",
                            to: createTeamMemberSlug,
                          },
                        },
                      ],
                    },
                  ],
                })}
              />
              <Button to={"/team/"} primary={true} as={InternalLink}>
                See All Stylists
              </Button>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
